@import "../../../../config/variables";

.products-container {
  display: flex;
  flex-direction: row;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;

    .paginate-container {
      ul {
        flex-wrap: nowrap;
      }
    }

    .product-cards {
      width: 100%;
      flex: 1;
      height: max-content;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @media (max-width: $mediaStep4) {
        justify-content: center;
      }
    }
  }
}

@media (max-width: $mediaStep2) {
  .products-container {
    flex-direction: column;
  }
}
