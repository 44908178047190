@import "/src/assets/commonStyles";
@import "/src/config/variables";

.management-main-container {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: $managementHeaderHeight;
  overflow: hidden;

  .content-pages-container {
    height: 100%;
    overflow-y: auto;

    .main-container {
      @extend .flexbox-center;
      flex-direction: column;
      margin-top: 30px;

      .main-card {
        width: 100%;
        max-width: 500px;
        padding: 30px;
        margin-bottom: 50px;
      }
    }
  }

  @media(max-width: $mediaStep3) {
    padding-top: 0;
    padding-bottom: 0;

    .content-pages-container {
      height: calc(100% - #{$managementHeaderMobileHeight});
    }
  }
}
