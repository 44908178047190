@import "/src/assets/commonStyles";

.preloader-modal-container {
  @extend .flexbox-center;
  width: 300px;
  height: 300px;
}

.management-products-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;

  .paginate-container ul {
    flex-wrap: nowrap;
  }

  .filter-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    position: relative;
    margin-bottom: 20px;

    .filter-preloader {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .products-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
  }
}
