.authorization-login-container {
  width: 100%;
  height: 100%;
  background: white;

  .form-container {
    width: 300px;
    max-width: 100%;
    height: max-content;
    max-height: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .form-element {
      margin-top: 30px;
      width: 100%;
    }

    .preloader-container {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
}
