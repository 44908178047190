.product-edit-modal {
  box-sizing: border-box;
  width: 800px;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;

  .preloader-container {
    margin: 20px;
  }

  .preview-image {
    width: 100%;
    max-width: 700px;
    object-fit: contain;
  }

  .field {
    margin-top: 20px;
    width: 100%;
  }

  .field-categories {
    margin-top: 20px;
    padding-top: 10px;
    width: 100%;
    max-height: 100px;
    overflow-y: auto;
  }

  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;

    .inner-container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
}
