.user-roles-edit-modal {
  box-sizing: border-box;
  width: 800px;
  max-width: 100%;
  max-height: 100%;
  min-height: 400px;
  margin: auto;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;

  .field {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
  }

  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;

    .inner-container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }

  table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }

  tr, th {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 3;
  }

  th {
    align-items: center;
  }

  th:last-child {
    flex: 1;
    justify-content: center;
  }

  button {
    height: max-content;
    margin: 0 10px;
  }
}
