@import "src/config/variables";

.demo-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 300px;
  height: 230px;
  margin: 15px;
  position: relative;

  @media(max-width: $mediaStep2) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .demo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    line-height: 50px;
    font-weight: 900;
    text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
    color: #fff6a9;
    font-family: 'Canciller';
    text-align: center;
    animation: blink 12s infinite;
    -webkit-animation: blink 12s infinite;


    @-webkit-keyframes blink {
      20%,
      24%,
      55% {
        color: #111;
        text-shadow: none;
      }

      0%,
      19%,
      21%,
      23%,
      25%,
      54%,
      56%,
      100% {
        /*     color: #fccaff;
            text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
              0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
        text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
        color: #fff6a9;
      }
    }

    @keyframes blink {
      20%,
      24%,
      55% {
        color: #111;
        text-shadow: none;
      }

      0%,
      19%,
      21%,
      23%,
      25%,
      54%,
      56%,
      100% {
        /*     color: #fccaff;
            text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
              0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
        text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
        color: #fff6a9;
      }
    }
  }
}

.demo-container-background {
  background: #222;
  background-image: repeating-linear-gradient(to bottom, transparent 7px, rgba(0, 0, 0, 0.8) 9px, rgba(0, 0, 0, 0.8) 13px, transparent 13px);
}
