$headerHeight: 80px;
$headerHeightElement: 40px;
$footerHeight: 30px;

$managementHeaderHeight: 50px;
$managementHeaderMobileHeight: 110px;

$categoryContainerWidth: 250px;
$categoryContainerMargin: 5px;

$mediaStep1: 1500px;
$mediaStep2: 1000px;
$mediaStep3: 800px;
$mediaStep4: 600px;

$colorGrayC4: #C4C4C4;
$colorTextBlack: rgba(0, 0, 0, 0.87);
