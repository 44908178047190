.shop-product-edit-modal {
  box-sizing: border-box;
  width: 800px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }

  tr, th {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1;
  }

  th {
    align-items: center;
  }

  button {
    height: max-content;
  }
}
