.schedule-card-container {
  box-sizing: border-box;
  width: 300px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 15px;
  text-align: left;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 15px;

  .container-hover-edit {
    position: absolute;
    background: rgba(255,255,255,0.9);
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    transition: bottom 0.5s;

    .edit-icon {
      position: absolute;
      width: 65px;
      height: 65px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    .container-hover-edit {
      cursor: pointer;
      bottom: 0;
    }
  }

  .icon-add {
    width: 65px;
    height: 65px;
    margin: auto;
  }
}
