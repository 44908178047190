.management-drawer-container {
  width: max-content;

  .link-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    text-decoration: none;
    font-weight: bolder;
    color: blue;
    padding: 0 20px;

    &:hover {
      background: rgba(0,0,0,0.1);
    }
  }
}
