.component-preloader-container {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .circle-loading-animation-container {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 50%;

    animation-name: rotation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
