.management-documents-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 75px 0;
  position: relative;

  .paginate-container {
    margin: 20px 0;
    ul {
      flex-wrap: nowrap;
    }
  }

  .edit-container {
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    background: white;
    z-index: 100;
    box-shadow: 0 1px 24px 0 rgba(0,0,0,0.14);

    button {
      max-width: 150px;
      flex-wrap: wrap;
    }

    .part-container {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex: 1;
      position: relative;

      .filter-preloader {
        position: absolute;
        right: 10px;
        top: 24px;
      }
    }

    .part-container-center {
      align-items: center;
      justify-content: center;
    }
  }

  table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }

  tr, th {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 3;
  }

  th {
    align-items: center;
  }

  th:first-child {
    flex: 1;
  }

  th:last-child {
    flex: 1;
    justify-content: center;
  }

  button {
    height: max-content;
    margin: 0 10px;
  }

  .text_fields_full {
    width: 100%;
  }

  a:visited {
    color: rgb(0, 0, 238);
  }
}
