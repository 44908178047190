@import "../../../../assets/commonStyles";

.about-main-container {
  @extend .flexbox-center;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 85%;
  width: 500px;
  text-align: left;
  margin: 0 auto;
  margin-top: 50px;

  .headline {
    font-weight: bolder;
  }
}
