.config-manager-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 300px;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 15px;
}
